// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-favoriten-js": () => import("./../../../src/pages/favoriten.js" /* webpackChunkName: "component---src-pages-favoriten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nach-bedeutung-js": () => import("./../../../src/pages/nach-bedeutung.js" /* webpackChunkName: "component---src-pages-nach-bedeutung-js" */),
  "component---src-pages-nach-beliebtheit-js": () => import("./../../../src/pages/nach-beliebtheit.js" /* webpackChunkName: "component---src-pages-nach-beliebtheit-js" */),
  "component---src-pages-nach-geschlecht-js": () => import("./../../../src/pages/nach-geschlecht.js" /* webpackChunkName: "component---src-pages-nach-geschlecht-js" */),
  "component---src-pages-nach-herkunft-js": () => import("./../../../src/pages/nach-herkunft.js" /* webpackChunkName: "component---src-pages-nach-herkunft-js" */),
  "component---src-pages-namenslexikon-js": () => import("./../../../src/pages/namenslexikon.js" /* webpackChunkName: "component---src-pages-namenslexikon-js" */),
  "component---src-pages-suche-js": () => import("./../../../src/pages/suche.js" /* webpackChunkName: "component---src-pages-suche-js" */),
  "component---src-templates-names-js": () => import("./../../../src/templates/names.js" /* webpackChunkName: "component---src-templates-names-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */)
}

